import React, { useState } from 'react'
import { WrapperSection, WrapperContainer } from '../../wrappers'
import Img from 'gatsby-image'
import { T } from '../../typo'
import { s, globals, colors, alpha } from '../../style'
import { ButtonMain } from '../../buttons'
import ExpandIcon from '@material-ui/icons/ExpandMore'
import { Collapse, Hidden } from '@material-ui/core'
import SideLine from '../../elements/SideLine/SideLine'

const InfoBlock = ({ data, theme, expanded }) => {
  const [isExpanded, setExpanded] = useState(expanded)
  return (
    <div
      css={sBlock}
      onClick={() => setExpanded(!isExpanded)}
      onKeyPress={() => setExpanded(!isExpanded)}
      role="button"
      tabIndex={0}>
      <div css={[sBlockBackground(theme), { opacity: isExpanded ? 1 : 0 }]} />
      <div css={[{ opacity: isExpanded ? 1 : 0.6 }, sBlockTitle]}>
        <ExpandIcon
          css={[
            sBlockTitle.icon,
            isExpanded && { transform: 'rotate(180deg)', opacity: 1 },
          ]}
        />
        <T d={20} m={16} condensed variant="h5" bold>
          {data.title}
        </T>
      </div>
      <Collapse in={isExpanded}>
        <T d={14} m={14} o={0.99} mt={1} light>
          {data.text}
        </T>
      </Collapse>
    </div>
  )
}

const sBlock = {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  borderRadius: globals.roundness.containers.mobile,
  [s.sm_down]: { padding: '1rem 1.5rem', marginTop: '1rem' },
  [s.md]: { padding: '1rem 1.5rem', marginTop: '1rem' },
}

const sBlockBackground = (theme) => ({
  transition: 'opacity 0.25s ease-out',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
  background: `linear-gradient(to right bottom, ${colors[theme].main.concat(
    alpha[1]
  )}, ${colors[theme].main.concat(alpha[8])})`,
})

const sBlockTitle = {
  '&:hover': {
    [s.hover]: {
      opacity: 1,
    },
  },
  transition: 'opacity 0.25s ease-out',
  display: 'flex',
  alignItems: 'center',
  icon: {
    opacity: 0.6,
    transition: 'transform 0.25s ease-out',
    fontSize: '2rem',
    marginRight: '1rem',
    [s.xs]: { marginRight: '0.5rem' },
  },
}

const InfoExpandable = ({ theme, data, useColor = true }) => {
  // console.log(data)
  const {
    button,
    description,
    titleObject,
    slug_section,
    block,
    images,
    language,
  } = data
  return (
    <WrapperSection
      noPaddingTop
      id={slug_section}
      css={{ contentVisibility: 'auto' }}
      outside={<SideLine theme={theme}>{slug_section}</SideLine>}>
      <WrapperContainer theme={theme} fullMobile>
        <div css={sContainer}>
          <div
            css={[sTextContainer, useColor && { color: colors[theme].main }]}>
            <T
              d={32}
              m={24}
              mb={1}
              bold
              condensed
              variant={titleObject.variant}>
              {titleObject.text}
            </T>
            <T
              d={14}
              t={14}
              m={14}
              o={0.99}
              mb={1}
              // extraCss={{ [s.md]: { maxWidth: '75%' } }}
            >
              {description}
            </T>
            <div>
              {block.map((data, id) => (
                <InfoBlock
                  key={id}
                  data={data}
                  theme={theme}
                  expanded={id === 1}
                />
              ))}
            </div>
            <Hidden mdUp>
              <ButtonMain
                center
                theme={theme}
                language={language}
                button={button}
                extraCss={sButton.mobile}>
                {button?.text}
              </ButtonMain>
            </Hidden>
          </div>
          {images && (
            <div css={sImagesContainer}>
              {images[0]?.image.childImageSharp?.fluid && (
                <div css={sImage1}>
                  <Img
                    style={sImage}
                    alt={images[0].image.name}
                    fluid={images[0].image.childImageSharp.fluid}
                  />
                </div>
              )}
              {images[1]?.image.childImageSharp?.fluid && (
                <div css={sImage2}>
                  <Img
                    style={sImage}
                    alt={images[1].image.name}
                    fluid={images[1].image.childImageSharp.fluid}
                  />
                </div>
              )}
              <Hidden smDown>
                <div css={sButton}>
                  <ButtonMain
                    theme={theme}
                    language={language}
                    button={button}
                    extraCss={sButton.extra}>
                    {button?.text}
                  </ButtonMain>
                </div>
              </Hidden>
            </div>
          )}
        </div>
      </WrapperContainer>
    </WrapperSection>
  )
}

const sContainer = {
  display: 'flex',
  [s.sm_down]: {
    flexDirection: 'column',
  },
}

const sTextContainer = {
  display: 'flex',
  flexDirection: 'column',
  [s.sm_down]: { marginBottom: globals.spacing.inside.mobile },
  [s.md]: { width: '55%' },
}

const sImagesContainer = {
  position: 'relative',
  // backgroundColor: 'rgba(0, 0, 255,  0.2)',
  [s.sm_down]: { width: '100%', height: 330 },
  // [s.xs]: { height: 330 },
  [s.md]: { width: '45%', marginLeft: globals.spacing.inside.desktop },
}

const sImage = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  borderRadius: 'inherit',
}

const sImage1 = {
  ...sImage,
  height: 300,
  borderRadius: '50%',
  [s.md]: {
    boxShadow: globals.shadows.pictures,
    borderRadius: '6rem',
  },
}

const sImage2 = {
  borderRadius: '50%',
  position: 'absolute',
  height: 200,
  width: 200,
  right: 48,
  top: 200,
  [s.sm_down]: {
    right: '50%',
    transform: 'translateX(50%)',
    bottom: -64,
    height: 160,
    width: 160,
    top: 'auto',
  },
  [s.sm_only]: {
    top: 180,
  },
  [s.md]: {
    boxShadow: globals.shadows.pictures,
  },
}

const sButton = {
  position: 'absolute',
  bottom: 0,

  left: '50%',
  transform: 'translateX(-50%)',
  // [s.md]: { marginTop: '2rem' },
  extra: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
  },

  mobile: {
    marginTop: globals.spacing.inside.mobile,
  },
}

export default InfoExpandable
